import { createRouter, createWebHistory } from "vue-router";
import MainView from "@/views/MainView.vue";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/register/RegisterView.vue";
import EmailVerification from "@/views/register/EmailVerification.vue";
import AccountView from "@/views/setting/AccountView";
import BookmarkView from "@/views/setting/BookmarkView";
import ResetPassword from "@/views/setting/ResetPassword";
import ProfileSetting from "@/views/setting/ProfileSetting";
import UserPreferenceSetting from "@/views/setting/UserPreferenceSetting";
import NotificationSetting from "@/views/setting/NotificationSetting";
import ResetPasswordView from "@/views/ResetPasswordView";
import RedirectView from "@/views/RedirectView.vue";
import DetailView from "@/views/DetailView";
import SearchView from "@/views/SearchView";
import SocialMediaView from "@/views/SocialMediaView";
import DiscoveryView from "@/views/discovery/DiscoveryView";
import DiscoveryUpcomingView from "@/views/discovery/DiscoveryUpcomingView";
import BuyView from "@/views/BuyView";
import CartBuy from "@/components/Buys/CartBuy";
import CartView from "@/views/cart/CartView";
import StoreView from "@/views/store/StoreView";
import ContactView from "@/views/footer/ContactView";
import AboutView from "@/views/footer/AboutView";
import PriceView from "@/views/footer/PriceView";
import HelpCenterView from "@/views/footer/HelpCenterView";
import HowToView from "@/views/guide/HowToView";
import NotFoundView from "@/views/error/NotFoundView.vue";
import store from "@/store/index";
import Cookies from "js-cookie";
import { CATEGORY } from "@/config/category";
import api from "@/api/api";
import MyOrder from "@/views/setting/MyOrder.vue";
import MyOrderDetail from "@/views/setting/MyOrderDetail.vue";
import ServiceError from "@/components/error/ServiceError.vue";
// import ListTicket from "@/views/setting/ListTicket.vue";
import MyOrderList from "@/views/setting/MyOrder.vue";
import DiscoveryPastEventView from "@/views/discovery/DiscoveryPastEventView.vue";

const routes = [
  {
    path: "/buy-now",
    name: "buy",
    component: BuyView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["cart"].length) {
        return next({
          name: "home",
        });
      }
      next();
    },
  },
  {
    path: "/buy-cart",
    name: "cart-buy",
    component: CartBuy,
    beforeEnter: (to, from, next) => {
      if (!store.state.carts.length > 0) {
        return next({
          name: "home",
        });
      }
      next();
    },
  },
  {
    path: "/purchase",
    name: "purchase",
    component: () => import("@/views/purchase/PurchaseContainer.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.state.cart.length > 0) {
        return next({
          name: "home",
        });
      }
      next();
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (Cookies.get("auth_token")) {
        return next({
          name: "home",
        });
      }
      next();
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    beforeEnter: (to, from, next) => {
      if (Cookies.get("auth_token")) {
        return next({
          name: "home",
        });
      }
      next();
    },
  },
  {
    path: "/verification",
    name: "verification",

    beforeEnter: (to, from, next) => {
      if (Cookies.get("auth_token")) {
        return next({
          name: "home",
        });
      }
      next();
    },
    children: [
      {
        path: "email",
        name: "email-verification",
        component: EmailVerification,
        beforeEnter: (to, from, next) => {
          if (!store.state.credential) {
            return next({ name: "home" });
          }
          next();
        },
      },
    ],
  },
  {
    path: "/reset-password",
    name: "reset",
    component: ResetPasswordView,
    beforeEnter: (to, from, next) => {
      if (Cookies.get("auth_token")) {
        return next({
          name: "home",
        });
      }
      next();
    },
  },
  {
    path: "/redirect",
    name: "redirect",
    component: RedirectView,
  },
  {
    path: "/",
    name: "main",
    component: MainView,
    redirect: () => {
      return { name: "home" };
    },
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "/account",
        name: "account",
        component: AccountView,
        beforeEnter: (to, from, next) => {
          if (Cookies.get("guest_token")) {
            return next({
              name: "home",
            });
          }
          next();
        },
        redirect: () => {
          return { name: "profile" };
        },
        children: [
          {
            path: "profile",
            name: "profile",
            component: ProfileSetting,
          },
          {
            path: "my-order",
            name: "my-order",
            component: MyOrderList,
          },
          {
            path: "order",
            name: "order",
            component: MyOrder,
          },
          {
            path: "order/:id",
            name: "order-detail",
            component: MyOrderDetail,
          },
          {
            path: "bookmark",
            name: "bookmark",
            component: BookmarkView,
          },
          {
            path: "setting",
            name: "setting",

            redirect: () => {
              return { name: "reset-password" };
            },
            children: [
              {
                path: "reset-password",
                name: "reset-password",
                component: ResetPassword,
              },
              {
                path: "user-pref",
                name: "user-pref",
                component: UserPreferenceSetting,
              },
              {
                path: "notification",
                name: "notification-setting",
                component: NotificationSetting,
              },
            ],
          },
        ],
      },
      {
        path: "/contact",
        name: "contact",
        component: ContactView,
      },
      {
        path: "/price",
        name: "price",
        component: PriceView,
      },
      {
        path: "/about",
        name: "about",
        component: AboutView,
      },
      {
        path: "/help-center",
        name: "help",
        component: HelpCenterView,
      },
      {
        path: "/how-to",
        redirect: () => {
          return { name: "subscription-kiosk" };
        },
        children: [
          {
            path: "subscription-kiosk",
            name: "subscription-kiosk",
            component: HowToView,
            meta: {
              name: "subscription-kiosk",
            },
          },
          {
            path: "sell-ticket",
            name: "sell-ticket",
            component: HowToView,
            meta: {
              name: "sell-ticket",
            },
          },
          {
            path: "buy-ticket",
            name: "buy-ticket",
            component: HowToView,
            meta: {
              name: "buy-ticket",
            },
          },
        ],
      },
      {
        path: "/detail/:id",
        name: "detail",
        component: DetailView,
      },
      {
        path: "/search",
        name: "search",
        component: SearchView,
      },
      {
        path: "/discovery/",
        children: [
          {
            path: "past-events",
            component: DiscoveryPastEventView,
            name: "past-events",
            meta: {
              breadcrumb: [
                {
                  title: "Beranda",
                  disabled: false,
                  href: "/",
                },
                {
                  title: "Event Sebelumnya",
                  disabled: true,
                  href: "/",
                },
              ],
              sort: [
                { name: "Terbaru", state: { order: "desc" } },
                {
                  name: "Harga Terendah",
                  state: { sort: "price", order: "asc" },
                },
                {
                  name: "Harga Tertinggi",
                  state: { sort: "price", order: "desc" },
                },
                {
                  name: "Waktu Mulai Terdekat",
                  state: { sort: "event_date", order: "asc" },
                },
                {
                  name: "Waktu Mulai Terjauh",
                  state: {
                    sort: "event_date",
                    order: "desc",
                  },
                },
              ],
            },
          },
          {
            path: "music-concert",
            component: DiscoveryView,
            name: "music-concert",
            meta: {
              category: CATEGORY.KONSER,
              breadcrumb: [
                {
                  title: "Beranda",
                  disabled: false,
                  href: "/",
                },
                {
                  title: "Event",
                  disabled: true,
                  href: "/",
                },
                {
                  title: "Konser",
                  disabled: true,
                  href: "/",
                },
              ],
              sort: [
                { name: "Terbaru", state: { order: "desc" } },
                {
                  name: "Harga Terendah",
                  state: { sort: "price", order: "asc" },
                },
                {
                  name: "Harga Tertinggi",
                  state: { sort: "price", order: "desc" },
                },
                {
                  name: "Waktu Mulai Terdekat",
                  state: { sort: "event_date", order: "asc" },
                },
                {
                  name: "Waktu Mulai Terjauh",
                  state: {
                    sort: "event_date",
                    order: "desc",
                  },
                },
              ],
            },
          },
          {
            path: "race",
            component: DiscoveryView,
            name: "race",
            meta: {
              category: CATEGORY.RACE,
              breadcrumb: [
                {
                  title: "Beranda",
                  disabled: false,
                  href: "/",
                },
                {
                  title: "Event",
                  disabled: true,
                  href: "/",
                },
                {
                  title: "Race",
                  disabled: true,
                  href: "/",
                },
              ],
              sort: [
                { name: "Terbaru", state: { order: "desc" } },
                {
                  name: "Harga Terendah",
                  state: { sort: "price", order: "asc" },
                },
                {
                  name: "Harga Tertinggi",
                  state: { sort: "price", order: "desc" },
                },
                {
                  name: "Waktu Mulai Terdekat",
                  state: { sort: "event_date", order: "asc" },
                },
                {
                  name: "Waktu Mulai Terjauh",
                  state: {
                    sort: "event_date",
                    order: "desc",
                  },
                },
              ],
            },
          },
          {
            path: "attraction",
            component: DiscoveryView,
            name: "attraction",
            meta: {
              category: CATEGORY.TAMAN_HIBURAN,
              breadcrumb: [
                {
                  title: "Beranda",
                  disabled: false,
                  href: "/",
                },
                {
                  title: "Atraksi",
                  disabled: true,
                  href: "/",
                },
              ],
              sort: [
                { name: "Terbaru", state: { order: "desc" } },
                {
                  name: "Harga Terendah",
                  state: { sort: "price", order: "asc" },
                },
                {
                  name: "Harga Tertinggi",
                  state: { sort: "price", order: "desc" },
                },
              ],
            },
          },
          {
            path: "healing-sejenak",
            component: DiscoveryView,
            name: "healing-sejenak",
            meta: {
              category: CATEGORY.HEALING,
              breadcrumb: [
                {
                  title: "Beranda",
                  disabled: false,
                  href: "/",
                },
                {
                  title: "Hiburan",
                  disabled: true,
                  href: "/",
                },
                {
                  title: "Healing Sejenak",
                  disabled: true,
                  href: "/",
                },
              ],
              sort: [
                { name: "Terbaru", state: { order: "desc" } },
                {
                  name: "Harga Terendah",
                  state: { sort: "price", order: "asc" },
                },
                {
                  name: "Harga Tertinggi",
                  state: { sort: "price", order: "desc" },
                },
              ],
            },
          },
          {
            path: "upcoming",
            component: DiscoveryUpcomingView,
            name: "discovery-upcoming",
            meta: {
              breadcrumb: [
                {
                  title: "Beranda",
                  disabled: false,
                  href: "/",
                },
                {
                  title: "Upcoming",
                  disabled: true,
                  href: "/",
                },
              ],
              sort: [
                { name: "Terbaru", state: { order: "desc" } },
                {
                  name: "Harga Terendah",
                  state: { sort: "price", order: "asc" },
                },
                {
                  name: "Harga Tertinggi",
                  state: { sort: "price", order: "desc" },
                },
              ],
            },
          },
        ],
      },
      {
        path: "/store/:user_id/:store_id",
        name: "store",
        component: StoreView,
      },
      {
        path: "/landing/:transaction_id",
        name: "landing",
        component: SocialMediaView,
      },
      {
        path: "/cart",
        name: "cart",
        component: CartView,
        beforeEnter: (to, from, next) => {
          if (Cookies.get("guest_token")) {
            return next({
              name: "home",
            });
          }
          next();
        },
      },
      {
        path: "/service-error",
        name: "service-error",
        component: ServiceError,
        beforeEnter: (to, from, next) => {
          if (!store.state.errorPage) {
            return next({
              name: "home",
            });
          }
          next();
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo({ top: 0, behavior: "smooth" });

  const userData = Cookies.get("user_data");
  const token = Cookies.get("user_data");

  if (!userData && !token) {
    store.commit("logout");

    await api
      .post("auth/guest")
      .then((response) => {
        const guest_token = response.data.response.guest_token;
        store.dispatch("guest", guest_token);
      })
      .catch((error) => {
        if (error.response.data.status_code) {
          switch (error.response.data.status_code) {
            case 500:
              store.state.errorPage = 500;
              router.push({ name: "service-error" });
              break;
            default:
              store.state.errorPage = true;
              break;
          }
        } else if (error.response.status) {
          switch (error.response.status) {
            case 500:
              store.state.errorPage = 500;
              router.push({ name: "service-error" });
              break;
            default:
              store.state.errorPage = true;
              break;
          }
        }
      });
  }

  next();
});

export default router;
